import React from "react";

export default function About() {
  return (
    <div className="about-wrapper" id="about">
      <div className="cnt-title">
        <p className="title1">About</p>
        <p className="cnt-subtitle">会社概要</p>
      </div>
      <div className="about-cnt">
        <table className="about-table">
          <tbody>
            <tr>
              <td>会社名</td>
              <td>株式会社DSKM</td>
            </tr>
            <tr>
              <td>所在地</td>
              <td>東京都渋谷区恵比寿南2-8-2 キョウデンビル7F</td>
            </tr>
            <tr>
              <td>代表</td>
              <td>増子大輔</td>
            </tr>
            <tr>
              <td>法人設立</td>
              <td>令和3年2月5日</td>
            </tr>
            <tr>
              <td>資本</td>
              <td>6,000万円</td>
            </tr>
            <tr>
              <td>事業内容</td>
              <td>
                再生エネルギー事業、時計の販売、<br></br>
                古物営業法に基づく古物商、<br></br>
                サプリメント等栄養補助食品の製造、販売及び輸入業他
              </td>
            </tr>
          </tbody>
        </table>
        <div className="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.3176451411223!2d139.70628811093513!3d35.64454483187494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b3f7e54988b%3A0x1ad3e0e2d04bc576!2z44CSMTUwLTAwMjIg5p2x5Lqs6YO95riL6LC35Yy65oG15q-U5a-_5Y2X77yS5LiB55uu77yY4oiS77ySIOWFsembu-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1688708392964!5m2!1sja!2sjp"
            width="500"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="map-iframe-desktop"
          ></iframe>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.3176451411223!2d139.70628811093513!3d35.64454483187494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b3f7e54988b%3A0x1ad3e0e2d04bc576!2z44CSMTUwLTAwMjIg5p2x5Lqs6YO95riL6LC35Yy65oG15q-U5a-_5Y2X77yS5LiB55uu77yY4oiS77ySIOWFsembu-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1688708392964!5m2!1sja!2sjp"
            width="300"
            height="180"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="map-iframe-mobile"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
