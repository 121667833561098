import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./layouts/header";
import Footer from "./layouts/footer";
import Home from "./pages/Home";
import Form from "./pages/Form";
import Policy from "./pages/Policy";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header>
          <Header />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form" element={<Form />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
