import React from "react";
import Service from "./Service";
import Contact from "./Contact";
import About from "./About";

export default function Home() {
  return (
    <div>
      <div className="wrapper">
        <div className="wrapper-text">
          <p className="w-txt-1">太陽光発電メンテナンスを</p>
          <p className="w-txt-2">私たちがサポートします</p>
        </div>
      </div>
      <Service />
      <Contact />
      <About />
    </div>
  );
}
