import React from "react";

export default function Policy() {
  return (
    <div className="policy-wrapper">
      <div className="title1 title2">
        <p>プライバシーポリシー</p>
      </div>
      <div className="policy-cnt">
        <p className="policy-subtitle">個人情報保護方針</p>
        <div className="policy-text">
          <div>
            株式会社DSKM（以降、「当社」）は、このWebサイトをご利用になるお客様のプライバシーを尊重することをお約束します。Webサイトを参照しただけでは、お客様の個人情報が収集されることはありません。
          </div>
          <br />
          <div>
            当社では、お客様が投稿される「お問合せ」をはじめ、氏名や電話番号、メールアドレスのような個人を特定できる情報（個人情報）の入力をお願いする場合があります。このような個人情報を以下の基準に従って管理し保護に努めます。プライバシーポリシーはインターネット上で当社のサービスを利用しているときに適応されます。
          </div>
          <br />
          <br />
          <div>
            1.
            当社は、このWebサイトで入力された個人情報については、合理的な技術的施策をとることにより、個人情報への不正な侵入、個人情報の紛失、改ざん、漏えいなどの危険防止に努めます。
          </div>
          <br />
          <div>
            2.
            当社は、お客様からご提供いただいた個人情報を、より良い製品・サービスを開発するため、お客様へ有用な情報をお届けするため、その他の正当な目的のためのみに使用いたします。
          </div>
          <br />
          <div>
            3.
            当社は、お客様の個人情報の保護に関係する日本の法令その他の規範を遵守するとともに、本ポリシーの内容を継続的に見直し、その改善に努めます。
          </div>
          <br />
          <div>
            4.
            当社は、お客様に無断で個人情報を収集し第三者に開示することはありません。もっとも、特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律によるなど、法的な根拠が認められる場合はこの限りではありません。
          </div>
          <br />
          <div>
            5.
            当社のWebサイトでは、Cookieの情報を使って個々のお客様ごとにカスタマイズしたサービスを提供したりアクセス数の集計を行ったりします。尚、個人情報・入力情報をCookieに書き込むことは一切行なっておりません。
          </div>
          <br />
          <div>
            6.
            このWebサイトよりリンクする第三者（リンク先）のインターネットサイトにおいて、閲覧者が提供する個人情報の取り扱いについては、当社は一切責任を負いません。各リンク先における個人情報の取り扱いに関する定め等をご覧ください。
          </div>
          <br />
          <div>
            7.
            本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、お客様に通知することなく変更できるものとします。当社が別途定める場合を除いて、変更後のプライバシーポリシーは本Webサイトに掲載したときから効力を生じるものとします。
          </div>
          <br />
          <br />
          <div>株式会社DSKM</div>
          <div>東京都港区六本木7-4-6 102号</div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
