import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header-wrapper">
      <Link to="/">
        <div className="logo-bland"></div>
      </Link>
      <div className="header-menu">
        <ul className="header-menu-list">
          <li className="header-menu-service">
            <a href="/#service">
              <p>事業内容</p>
            </a>
          </li>
          <li className="header-menu-about">
            <a href="/#about">
              <p>会社概要</p>
            </a>
          </li>
          <li className="header-menu-form">
            <Link to="/form">
              <p>お問い合わせ</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
