import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="f-logo"></div>
      <Link to="/policy" className="policy-link">
        <div className="policy-link">プライバシーポリシー</div>
      </Link>
      <div className="copyright">@DSKM Allrights Reserved.</div>
    </div>
  );
}
