import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Form() {
  const [formStatus, setFormStatus] = useState(false);
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("送信中");

    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });
    axios
      .post(
        "https://getform.io/f/977ba227-cb66-464f-8c24-9ad7c0223133",
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          name: "",
          email: "",
          message: "",
        });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="form-wrapper">
      <div className="form-title">お問い合わせ</div>
      <form
        acceptCharset="UTF-8"
        method="POST"
        onSubmit={handleSubmit}
        id="gatsbyForm"
        className="contact-form"
      >
        <label>氏名</label>
        <br></br>
        <input
          type="text"
          name="name"
          required
          value={query.name}
          onChange={handleChange()}
          className="input-text"
          placeholder=""
        ></input>
        <br></br>
        <label>メールアドレス</label>
        <br></br>
        <input
          type="email"
          name="email"
          required
          value={query.email}
          onChange={handleChange()}
          className="input-text"
          placeholder="example@email.com"
        ></input>
        <br></br>
        <label>お問い合わせ内容</label>
        <br></br>
        <textarea
          type="message"
          name="message"
          required
          value={query.message}
          onChange={handleChange()}
          className="input-textarea"
          placeholder=""
        ></textarea>
        <p className="con-txt">
          送信をすることで本サイトの
          <span className="policy-link-f">
            <Link to="/policy">プライバシーポリシー</Link>
          </span>
          に同意したこととみなします。
        </p>
        <button type="submit" className="contact-btn">
          送信
        </button>
      </form>
      {formStatus ? (
        <div className="alert alert-success">メールが送信されました！</div>
      ) : (
        ""
      )}
    </div>
  );
}
