import React from "react";

export default function Service() {
  return (
    <div className="service-wrapper" id="service">
      <div className="cnt-title">
        <p className="title1">Service</p>
        <p className="cnt-subtitle">事業内容</p>
      </div>
      <div className="ser-cnt1">
        <div className="ser-cnt1-subtitle">太陽光発電システムの保守管理</div>
        <p className="ser-cnt1-txt">
          設計・施工から運用・保守までの業務をご提供します
        </p>
      </div>
      <div className="ser-cnt2">
        <ul className="ser-cnt">
          <li className="ser-cnt-image1 c-bnr bg">
            <span className="ser-menu">定期点検業務</span>
          </li>
          <li className="ser-cnt-image2">
            <span className="ser-menu">遠隔監視業務</span>
          </li>
        </ul>
        <ul className="ser-cnt">
          <li className="ser-cnt-image3">
            <span className="ser-menu">除草・パネル洗浄業務</span>
          </li>
          <li className="ser-cnt-image4">
            <span className="ser-menu">緊急対応業務</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
