import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div className="contact-wrapper" id="contact">
      <div className="cnt-title">
        <p className="title1">Contact</p>
        <p className="cnt-subtitle">お問い合わせ</p>
      </div>
      <div className="contact-txt">
        <p>ご相談・お見積もりはこちら</p>
        <Link to="/form" className="form-cnt">
          <div className="form-btn">お問い合わせフォーム</div>
        </Link>
      </div>
      <div className="contant-cover"></div>
    </div>
  );
}
